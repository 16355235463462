<template>
    <div class="container-fluid">
        <b-row>
            <b-col lg="12">
                <card class=" card-block card-stretch">
                    <template v-slot:headerTitle>
                        <h4 class="card-title mb-0">Coeficiente de Competencia Intelinsurance | Comparador</h4>
                    </template>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="2">
                                <div class="form-group ">
                                    <label for="model">Fecha</label>
                                    <select class="form-control mb-3" id="configuration_list" name="configuration_list"
                                        @change="setResultsFilter($event, 'fecha')">
                                        <option value="june-23">Junio '23</option>
                                        <option value="july-23">Julio '23</option>
                                        <option value="august-23">Agosto '23</option>
                                        <option value="september-23">Septiembre '23</option>
                                        <option value="october-23">Octubre '23</option>
                                        <option value="november-23">Noviembre '23</option>
                                        <option value="dicember-23">Diciembre '23</option>
                                        <option value="january-24">Enero '24</option>
                                        <option value="february-24">Febrero '24</option>
                                        <option value="march-24">Marzo '24</option>
                                        <option value="april-24">Abril '24</option>
                                        <option value="may-24">Mayo '24</option>
                                        <option value="june-24">Junio '24</option>
                                        <option value="july-24">Julio '24</option>
                                        <option value="august-24">Agosto '24</option>
                                        <option value="september-24">Septiembre '24</option>
                                        <option value="october-24">Octubre '24</option>
                                        <option value="november-24">Noviembre '24</option>
                                        <option value="dicember-24">Diciembre '24</option>
                                    </select>
                                </div>
                            </b-col>
                            <b-col lg="3">
                                <div class="form-group ">
                                    <label for="model">Fecha Comparativa</label>
                                    <select class="form-control mb-3" id="configuration_list" name="configuration_list"
                                        @change="setResultsFilter($event, 'fecha-2')">
                                        <option value="june-23">Junio '23</option>
                                        <option value="july-23">Julio '23</option>
                                        <option value="august-23">Agosto '23</option>
                                        <option value="september-23">Septiembre '23</option>
                                        <option value="october-23">Octubre '23</option>
                                        <option value="november-23">Noviembre '23</option>
                                        <option value="dicember-23">Diciembre '23</option>
                                        <option value="january-24">Enero '24</option>
                                        <option value="february-24">Febrero '24</option>
                                        <option value="march-24">Marzo '24</option>
                                        <option value="april-24">Abril '24</option>
                                        <option value="may-24">Mayo '24</option>
                                        <option value="june-24">Junio '24</option>
                                        <option value="july-24">Julio '24</option>
                                        <option value="august-24">Agosto '24</option>
                                        <option value="september-24">Septiembre '24</option>
                                        <option value="october-24">Octubre '24</option>
                                        <option value="november-24">Noviembre '24</option>
                                        <option value="dicember-24">Diciembre '24</option>
                                    </select>
                                </div>
                            </b-col>
                            <b-col lg="2">
                                <div class="form-group ">
                                    <label for="model">Pago</label>
                                    <select class="form-control mb-3" id="configuration_list" name="configuration_list"
                                        @change="setResultsFilter($event, 'payment')">
                                        <option value="-">Seleccione</option>
                                        <option value="1">Efectivo</option>
                                        <option value="2">Tarjeta</option>
                                    </select>
                                </div>
                            </b-col>
                            <b-col lg="3">
                                <div class="form-group ">
                                    <label for="model">Localidades</label>
                                    <select class="form-control mb-3" id="configuration_list" name="configuration_list"
                                        @change="setResultsFilter($event, 'place')">
                                        <option value="-">Seleccione</option>
                                        <option v-for="place in configuration_places" :value="place.id" :key="place.id">
                                            {{ place.city }}
                                        </option>
                                    </select>
                                    <!-- <treeselect class="form-control form-multiple mb-3"
                                        @input="setResultsFilter($event, 'place')" :multiple="false"
                                        :options="configuration_places" /> -->
                                </div>
                            </b-col>
                            <b-col lg="2">
                                <div class="form-group">
                                    <label for="model" style="min-height:24px; width:100%;"></label>
                                    <button type="submit" class="btn btn-primary"
                                        @click="getResultsFilter()">Filtrar</button>
                                </div>
                            </b-col>
                        </b-row>
                    </template>
                </card>
                <card class=" card-block card-stretch">
                    <template v-slot:body>
                        <div class="table-responsive data-table">
                            <div class="buttons col-12"></div>
                            <table class="data-tables table" id="datatable-new" style="width:100%">
                                <thead>
                                    <tr>
                                        <th width="" v-for="tableHead in quotes_statistics_companies">{{ tableHead }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="quotes in quotes_statistics_values">
                                        <td v-if="quotes.cobertura != 'Totales'" style="text-align: center;">
                                            {{ quotes.cobertura }}
                                        </td>
                                        <td v-if="quotes.cobertura == 'Totales'"
                                            style="text-align: center; font-weight: bold; color: crimson;">
                                            {{ quotes.cobertura }}
                                        </td>
                                        <td v-for="quote in quotes.resultados" v-if="quotes.cobertura != 'Totales'">
                                            <span v-if="quote.coef_company > 1.14" class="greater">{{ quote.coef_company
                                                }}</span>
                                            <span v-if="quote.coef_company > 0.85 && quote.coef_company < 1.15"
                                                class="">{{ quote.coef_company
                                                }}</span>
                                            <span v-if="quote.coef_company < 0.86" class="cheaper">{{ quote.coef_company
                                                }}</span>
                                        </td>
                                        <td v-for="quote in quotes.resultados" v-if="quotes.cobertura == 'Totales'"
                                            style="font-size: 15px;">
                                            <span style="font-size: 15px; font-weight: bold;"> {{
                                            (quote.coef_total / quote.coef_count).toFixed(2) }}</span>
                                            <!-- <span v-if="(quote.coef_total / quote.coef_count) > 1" class="greater">{{
                            (quote.coef_total / quote.coef_count).toFixed(2) }}</span>
                                            <span v-if="(quote.coef_total / quote.coef_count) == 1" class="equal">{{
                            (quote.coef_total / quote.coef_count).toFixed(2) }}</span>
                                            <span v-if="(quote.coef_total / quote.coef_count) < 1" class="cheaper">{{
                                                (quote.coef_total/quote.coef_count).toFixed(2) }}</span> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </card>
                <card class=" card-block card-stretch">
                    <template v-slot:body>
                        <div class="table-responsive data-table">
                            <div class="buttons col-12"></div>
                            <table class="data-tables table" id="datatable-new" style="width:100%">
                                <thead>
                                    <tr>
                                        <th width="" v-for="tableHead_2 in quotes_statistics_companies_2">{{ tableHead_2
                                            }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="quotes in quotes_statistics_values_2">
                                        <td v-if="quotes.cobertura != 'Totales'" style="text-align: center;">
                                            {{ quotes.cobertura }}
                                        </td>
                                        <td v-if="quotes.cobertura == 'Totales'"
                                            style="text-align: center; font-weight: bold; color: crimson;">
                                            {{ quotes.cobertura }}
                                        </td>
                                        <td v-for="quote in quotes.resultados" v-if="quotes.cobertura != 'Totales'">
                                            <span v-if="quote.coef_company > 1.14" class="greater">{{
                                            quote.coef_company
                                        }}</span>
                                            <span v-if="quote.coef_company > 0.85 && quote.coef_company < 1.15"
                                                class="">{{ quote.coef_company
                                                }}</span>
                                            <span v-if="quote.coef_company < 0.86" class="cheaper">{{
                                            quote.coef_company
                                        }}</span>
                                        </td>
                                        <td v-for="quote in quotes.resultados" v-if="quotes.cobertura == 'Totales'"
                                            style="font-size: 15px;">
                                            <span style="font-size: 15px; font-weight: bold;"> {{
                                            (quote.coef_total / quote.coef_count).toFixed(2) }}</span>
                                            <!-- <span v-if="(quote.coef_total / quote.coef_count) > 1" class="greater">{{
                            (quote.coef_total / quote.coef_count).toFixed(2) }}</span>
                                            <span v-if="(quote.coef_total / quote.coef_count) == 1" class="equal">{{
                            (quote.coef_total / quote.coef_count).toFixed(2) }}</span>
                                            <span v-if="(quote.coef_total / quote.coef_count) < 1" class="cheaper">{{
                                                (quote.coef_total/quote.coef_count).toFixed(2) }}</span> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </card>
            </b-col>
            <!--b-col sm="2" lg="2" class="wookie-col-2">
               <img src="@/assets/images/logo-wookie-alt-white.png" alt="">
            </b-col-->
        </b-row>
    </div>
</template>
<script>
import { core } from '../../../config/pluginInit'
import { mapGetters, mapState } from 'vuex'
import * as am4core from '@amcharts/amcharts4/core'
// import  AmCharts  from "../../../components/charts/AmChart";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import store from "@/store/index";
export default {
    components: {
        // AmCharts,
        // ApexChart,
        Treeselect
    },
    data() {
        return {
            today: '',
            contactList: [''],
            configuration_list: [],
            configuration_places: [],
            configuration_cars: [],
            configuration_result: [],
            configuration_result_historical: [],
            quotes_statistics_companies: [],
            quotes_statistics_values: [],
            quotes_statistics_companies_2: [],
            quotes_statistics_values_2: [],
            car_history: [],
            ipc_history: [
                { 'month': "9", 'amount': 1.1207, 'year': '2023', accumulates: 12.07 },
                { 'month': "10", 'amount': 1.0830, 'year': '2023', accumulates: 20.37 },
                { 'month': "11", 'amount': 1.1280, 'year': '2023', accumulates: 33.17 },
                { 'month': "12", 'amount': 1.2550, 'year': '2023', accumulates: 58.67 },
                { 'month': "1", 'amount': 1.2060, 'year': '2024', accumulates: 79.27 },
                { 'month': "2", 'amount': 1.1320, 'year': '2024', accumulates: 92.47 },
                { 'month': "3", 'amount': 1.1100, 'year': '2024', accumulates: 103.47 },
                { 'month': "4", 'amount': 1.0880, 'year': '2024', accumulates: 111.55 },
                { 'month': "5", 'amount': 1.0420, 'year': '2024', accumulates: 115.75 },
                { 'month': "6", 'amount': 1.0460, 'year': '2024', accumulates: 115.75 },
                { 'month': "7", 'amount': 1.0460, 'year': '2024', accumulates: 115.75 }
            ],
            car_result_history: [],
            selectedItems: [],
            dataTable: '',
            deleteWarning: 'Eliminando elementos...',
            doneDelete: 0,
            isSelectAll: false,
            getResultados: false,
            filterCompany: '',
            filterPlace: '',
            filterCar: '',
            filterCarName: '',
            filterQuoteGroup: '',
            filterBenchDate: '',
            filterPayment: '',
            dataFilter: {},
            componentKey: 10
        }
    },
    name: 'ResultsFilter',
    computed: {
        ...mapState(["productores", "infoauto", "procloud", "intelinsuranceApi"])
    },
    mounted() {


        console.log(this.intelinsuranceApi.user.configuration_id);
        console.log(this.intelinsuranceApi.user.company);
        this.getNow()
        store
            .dispatch("procloud/fetchConfigurationList")
            .then(() => {
                this.configuration_list = this.procloud.benchkmarking_configuration_list
            });



        store
            .dispatch("procloud/fetchConfigurationPlaces", this.intelinsuranceApi.user.configuration_id)
            .then(() => {
                this.configuration_places = this.procloud.benchkmarking_configuration_places
            });
        store
            .dispatch("procloud/fetchConfigurationData", this.intelinsuranceApi.user.configuration_id)
            .then(() => {
                this.configuration_places = this.procloud.benchkmarking_configuration_data.places
                this.configuration_cars = this.procloud.benchkmarking_configuration_data.cars
                this.configuration_companies = this.procloud.benchkmarking_configuration_data.companies
                //console.log(this.procloud.benchkmarking_configuration_data);
                //this.configuration_data = this.procloud.benchkmarking_configuration_data
            });

        //this.list()

    },
    beforeMount() {
        $('body').attr('id', "list-result");
    },
    destroyed() {
        //core.initDataTable()
    },
    methods: {
        getNow: function () {
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            this.today = date;
        },
        setResultsFilter: function (event, type) {

            this.dataFilter = {};



            if (type == 'companies') {
                this.filterCompany = event.target.value;
            }
            if (type == 'place') {
                this.filterPlace = event.target.value;
            }
            if (type == 'car') {
                const infoauto = event.target.selectedOptions[0].getAttribute('data-infoauto');
                const carYear = event.target.selectedOptions[0].getAttribute('data-year');
                this.filterCarInfoauto = infoauto;
                this.filterCarYear = carYear;
                this.filterCar = event.target.value;
                this.filterCarName = event.target.value.name;
            }
            if (type == 'cobertura') {
                this.filterQuoteGroup = event.target.value;
            }
            if (type == 'fecha') {
                this.filterBenchDate = event.target.value;
            }
            if (type == 'fecha-2') {
                this.filterBenchDateCompare = event.target.value;
            }
            if (type == 'payment') {
                this.filterPayment = event.target.value;
            }

            if (this.filterCompany != '') this.dataFilter.company = this.filterCompany
            if (this.filterPlace != '') this.dataFilter.location = this.filterPlace
            if (this.filterCar != '') this.dataFilter.car = this.filterCar
            if (this.filterCarInfoauto != '') this.dataFilter.infoauto = this.filterCarInfoauto
            if (this.filterCarYear != '') this.dataFilter.carYear = this.filterCarYear
            if (this.filterQuoteGroup != '') this.dataFilter.grupo = this.filterQuoteGroup
            if (this.filterBenchDate != '') this.dataFilter.fecha = this.filterBenchDate
            if (this.filterBenchDateCompare != '') this.dataFilter.fecha_2 = this.filterBenchDateCompare
            if (this.filterPayment != '') this.dataFilter.payment = this.filterPayment

            //this.dataFilter.payment = 123
            this.dataFilter.configurationId = this.intelinsuranceApi.user.configuration_id
            this.dataFilter.companyReference = this.intelinsuranceApi.user.company

            console.log(this.dataFilter);

        },
        getResultsFilter: function (event, type) {

            // this.configuration_result = '';
            this.getIndiceByQuotes()
            // store
            //     .dispatch("procloud/fetchConfigurationResult", this.dataFilter)
            //     .then(() => {
            //         this.configuration_result = this.procloud.benchkmarking_configuration_result;

            //         // if (this.intelinsuranceApi.user.company == 'Sancor Seguros' || this.intelinsuranceApi.user.company == 'Allianz') {
            //         this.getIndiceByQuotes()

            //         // }
            //     });
        },
        getIndiceByQuotes: function () {
            var dataFilterIndice = { ...this.dataFilter }
            this.quotes_statistics_values = [];
            this.quotes_statistics_companies = [];
            store.dispatch("procloud/fetchIndiceByQuotes", dataFilterIndice).then(() => {
                this.quotes_statistics_companies.push(dataFilterIndice.fecha)
                this.procloud.benchkmarking_indice_quotes[0].resultados.forEach(element => {
                    this.quotes_statistics_companies.push(element.company_name)
                    // arrayQuotesValueA.push(element.coef_company)
                });
                this.quotes_statistics_values = this.procloud.benchkmarking_indice_quotes;

                var dataFilterIndiceCompare = { ...this.dataFilter }
                dataFilterIndiceCompare.fecha = dataFilterIndiceCompare.fecha_2
                this.quotes_statistics_values_2 = [];
                this.quotes_statistics_companies_2 = [];
                store.dispatch("procloud/fetchIndiceByQuotes", dataFilterIndiceCompare).then(() => {
                    this.quotes_statistics_companies_2.push(dataFilterIndiceCompare.fecha)
                    this.procloud.benchkmarking_indice_quotes[0].resultados.forEach(element => {
                        this.quotes_statistics_companies_2.push(element.company_name)
                        // arrayQuotesValueA.push(element.coef_company)
                    });
                    this.quotes_statistics_values_2 = this.procloud.benchkmarking_indice_quotes;
                })
            })



        },
        list: function () {
            let contactData = {
                "status": "published",
                "first_name": this.first_name,
                "last_name": this.last_name,
                "whatsapp": this.whatsapp,
                "owner": 2
            }

            this.$store.dispatch('wookieContact/list', contactData.owner)
                .then((res) => {
                    this.contactList = res;
                    var selectedItems = this.selectedItems;
                    setTimeout(function () {
                        this.dataTable = $('#datatable').DataTable({
                            select: {
                                style: 'multi'
                            },
                            autoFill: {
                                update: true
                            }, language: {
                                url: '//cdn.datatables.net/plug-ins/1.11.5/i18n/es-ES.json'
                            },
                            buttons: [
                                'selectAll',
                                'selectNone'
                            ]
                        });

                        dataTable.on('select', function (e, dt, type, indexes) {
                            var rowData = dataTable.rows(indexes).data().toArray();
                            var thisID = rowData[0]['DT_RowId'].split('-')[1];
                            selectedItems.push(thisID);
                            console.log(selectedItems);

                            //events.prepend( '<div><b>'+type+' selection</b> - '+JSON.stringify( rowData )+'</div>' );
                        })
                            .on('deselect', function (e, dt, type, indexes) {
                                var rowData = dataTable.rows(indexes).data().toArray();
                                var thisID = rowData[0]['DT_RowId'].split('-')[1];
                                const index = selectedItems.indexOf(thisID);
                                selectedItems.splice(index, 1);

                                console.log(selectedItems);
                                //events.prepend( '<div><b>'+type+' <i>de</i>selection</b> - '+JSON.stringify( rowData )+'</div>' );
                            });
                    }, 1500);
                })
                .catch(err => console.log(err))
        }
    }
}
</script>
<style scoped>
span.select-info {
    display: none !important;
}

.warning-delete {
    display: inline-block;
    margin-bottom: 1.5rem !important;
    padding: 10px 20px;
    text-align: center;
    vertical-align: middle;
}

#list-agenda {
    background: yellow;
}

.a {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
    height: 25px;
    width: 31px;

}

.b {
    position: relative;
    bottom: 5px;
    right: 1px;
}

td.cheaper {
    color: #535f6b;
    background: #ffbdbd;
}

td.greater {
    color: #535f6b;
    background: #bdffcd;
}

td.equal {
    background: #acdcff;
}

.form-multiple .vue-treeselect__control {
    background: none !important;
    border: 0;
    padding: 0;
}

#datatable-new th {
    text-align: center;
    font-size: 15px;
}

#datatable-new .table td {
    padding: 0;
    max-width: 100px;
}

#datatable-new .table td span {
    display: block;
    max-width: 80px;
    padding: 0.75rem;
}

#datatable-new td span.greater {
    background: #ffbdbd;
}

#datatable-new td span.equal {
    background: #acdcff;
}

#datatable-new td span.cheaper {
    background: #bdffcd;
}
</style>

<style>
.form-multiple {
    padding-top: 0;
}

.vue-treeselect__control {
    height: 44px !important;
    background: #fafbfe !important;
    border: 1px solid #f5f5f6 !important;
    background: none !important;
    border: 0;
    padding: 0;
}

.vue-treeselect__placeholder {
    padding: 5px;
    color: #535f6b !important;
}


#datatable-new th {
    text-align: center;
    font-size: 15px;
}

#datatable-new td {
    padding: 0.25rem;
}

#datatable-new td span {
    display: block;
    padding: 0.50rem;
    text-align: center;
}

#datatable-new .table td span.greater {
    background: #ffbdbd;
}

#datatable-new .table td span.equal {
    background: #acdcff;
}

#datatable-new .table td span.cheaper {
    background: #bdffcd;
}
</style>